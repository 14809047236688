import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Modal } from '../components/Modal';
import { useCallback } from 'react';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';
import { withSidebar } from '../layout';
import { Box, Link, Heading, Paragraph, Image } from '../components';
import { Section } from '../components/Section';
import { SidebarHiring } from '../components/SidebarHiring';
import { SpinningText, Scene } from '../components/HeroSpinningText';
import { YoutubeVideo } from '../components/YoutubeVideo';
export const badge = <Box bg="textPrimary" py={1} px={2} display={['none', 'inline-flex']} transform="translateY(-50%) rotate(-25deg)" mr="-60px" mdxType="Box">
    <Link href="mailto:work@k-f.co" target="_blank" whiteSpace="nowrap" variant="heading3" color="backgroundPrimary" textDecoration="none" mdxType="Link">
      We're hiring!
    </Link>
  </Box>;
export const _frontmatter = {};
const layoutProps = {
  badge,
  _frontmatter
};
const MDXLayout = styled(withSidebar(<SidebarHiring />))``;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* Hero*/
    }
    <Scene perspective="800px" height="500px" mdxType="Scene">
  <SpinningText duration="30s" fontSize={['1.4rem', '1.8rem', '2.4rem']} rotateX={-15} rotateZ={-15} text="    OUR MISSION IS TO BUILD & SUPPORT A FINANCIAL ECOSYSTEM ON THE CENTRIFUGE PROTOCOL" mdxType="SpinningText" />
    </Scene>
    {
      /* Section */
    }
    <Section headline="About" mdxType="Section">
      <p>{`k/factory is a software studio founded by the original contributors to the Centrifuge protocol.`}</p>
      <p>{`We are building open source software powering the financial system of tomorrow.`}</p>
      <p>{`We contribute to the Centrifuge protocol by providing core infrastructure and work with our users to ensure adoption.`}</p>
    </Section>
    {
      /* Section */
    }

    <Section Heading={props => <Heading badge={badge} {...props} mdxType="Heading" />} headline="Work with us" mdxType="Section">
      <p>{`k/factory is self-managed and remote friendly. You can read more about how we run things in our `}<a parentName="p" {...{
          "href": "https://handbook.k-f.co"
        }}>{`handbook`}</a>{`.`}</p>
      <p>{`We offer competitive compensation based on your skills, not your gender or background. We are a diverse and inclusive team with a strong set of shared values.`}</p>
      <p>{`Learn more about who we are and why you should join below.`}</p>
    </Section>
    {
      /* Centrifuge logo */
    }
    <Box textAlign="center" mb={5} mdxType="Box">
      <Paragraph m={0} mb={2} mdxType="Paragraph">
  k/factory is a contributor to
      </Paragraph>
      <Image src={// eslint-disable-next-line @typescript-eslint/no-var-requires
      require('../images/centrifuge-logo.svg').default} width={183} height={55} mdxType="Image" />
    </Box>
    {
      /* Video */
    }
    <YoutubeVideo videoId="h8tfJ9EtIpY" posterUrl={require('../images/video-preview.jpg').default} mb={4} mdxType="YoutubeVideo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      