import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols);
} return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); });
} return target; }
import { baseTheme } from '@centrifuge/fabric/dist/theme/tokens/baseTheme';
import { modeDark } from '@centrifuge/fabric/dist/theme/tokens/modeDark';
var breakpoints = ["600px", "800px", "1140px", "1920px"];
var typography = _objectSpread(_objectSpread({}, baseTheme.typography), {}, { body1: { fontSize: ["16px", "16px", "18px"], fontWeight: 400, lineHeight: [1.3, 1.3, 1.4] }, body2: { fontSize: ["14px", "14px", "16px"], fontWeight: 400, lineHeight: 1.5 }, heading1: { fontSize: ['3rem', '3rem', '4rem'], fontWeight: 600, lineHeight: [0.6, 1] }, heading2: { fontSize: '3rem', fontWeight: 600, lineHeight: 1 }, heading3: { fontSize: '2rem', fontWeight: 600, lineHeight: 1 }, heading4: { fontSize: '1rem', fontWeight: 600, lineHeight: 1,
        textDecoration: 'underline' }, heading5: { fontSize: '1rem', fontWeight: 600, lineHeight: 1 }, heading6: { fontSize: '1rem', fontWeight: 600, lineHeight: 1 } });
var sizes = _objectSpread(_objectSpread({}, baseTheme.sizes), {}, { section: 920, modal: 720 });
var space = [0, 8, 16, 24, 40, 64];
var fonts = _objectSpread(_objectSpread({}, baseTheme.fonts), {}, { standard: "Manrope, sans-serif" });
var colors = _objectSpread(_objectSpread({}, modeDark.colors), {}, { brand: "#eafc99", borderPrimary: modeDark.colors.textPrimary });
var mediaQueries = { small: "@media screen and (min-width: " + breakpoints[0] + ")", medium: "@media screen and (min-width: " + breakpoints[1] + ")", large: "@media screen and (min-width: " + breakpoints[2] + ")" };
export var theme = _objectSpread(_objectSpread(_objectSpread({}, baseTheme), modeDark), {}, { breakpoints: Object.assign(breakpoints, { M: breakpoints[0], S: breakpoints[1], L: breakpoints[2], XL: breakpoints[3] }), typography: typography, sizes: sizes, fonts: fonts, colors: colors, space: Object.assign(space, { gutterMobile: space[1], gutterTablet: space[2], gutterDesktop: space[3] }), mediaQueries: mediaQueries });
export default theme;
